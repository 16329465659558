export interface TriggerDistributionConfig {
    triggers: HighLevelTriggerType
}

export enum RuleType {
    EVENT = 'event',
    SESSION_DURATION = 'session_duration',
    SINCE_LAST = 'since_last',
    SCREEN = 'screen',
    APP_OPEN = 'app_open'
}
export interface TriggerRule {
    type: RuleType
    conditional: string
    eventName: string
    value: string
}

export interface HighLevelTriggerType {
    type: TriggerEnum
    conditional: OnCondition
    appOpen: OnAppOpen
    screen: OnScreen
}

export interface OnAppOpen {}

export interface OnScreen {
    rules: Array<RuleSet>
}
export interface RuleSet {
    triggers: Array<TriggerRule>
    startWithKnob: RuleSetWithKnob | null
}

export const getSurveyDelaySec = (ruleSet: RuleSet): number => {
    try {
        return parseInt(ruleSet.triggers.filter((e) => e.type == RuleType.SESSION_DURATION)?.[0].value)
    } catch {
        console.log('Error parsing survey delay')
        return 0
    }
}

export interface RuleSetWithKnob {
    text: string
}

export interface OnCondition {
    rules: Array<RuleSet>
}

export enum TriggerEnum {
    ON_APP_OPEN = 'app_open',
    ON_SCREEN = 'screen',
    ON_CONDITION = 'conditional'
}
export const reverseMapTriggerString = (triggerType: string) => {
    switch (triggerType) {
        case 'app_open':
            return TriggerEnum.ON_APP_OPEN
        case 'screen':
            return TriggerEnum.ON_SCREEN
        case 'conditional':
            return TriggerEnum.ON_CONDITION
        default:
            throw new Error('Invalid trigger type')
    }
}

export const DefaultTriggerDistributionConfig: TriggerDistributionConfig = {
    triggers: {
        type: TriggerEnum.ON_APP_OPEN,
        appOpen: {},
        screen: {
            rules: []
        },
        conditional: {
            rules: []
        }
    }
}
