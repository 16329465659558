export interface GenericAppLifecycle {
    set windowLifecycleListener(listener: OnWindowLifecycleListener)
    set appLifecycleListener(listener: AppLifecycleListener)
}

export interface OnWindowLifecycleListener {
    onWindow(state: WindowState): void
}
export enum WindowState {
    CREATED,
    OPENED,
    CLOSED,
    DESTROYED
}

export interface AppLifecycleListener {
    onLifecycleEvent(state: AppState): void
}
export enum AppState {
    CREATED,
    FOREGROUND,
    BACKGROUND
}
