import { FeebaConfig, LocalStateHolder, ServerConfig } from './data'
import { WebStateStorage } from './data/web_storage'
import { WebLifecycleManager } from './lifecycle'
import { StateManager } from './state_manager'
import { TriggerValidator } from './trigger_validator'
import { Logger } from './utils'

let isInitialized = false
let config: FeebaConfig
let localStateHolder: LocalStateHolder
let stateManager: StateManager
let triggerValidator: TriggerValidator = new TriggerValidator()

export const Feeba = {
    config: config!,
    init: (serverConfig: ServerConfig): boolean => {
        if (isInitialized) {
            console.warn('Feeba already initialized')
            return false
        }
        config = { serviceConfig: serverConfig }
        // Initialize core components
        localStateHolder = new LocalStateHolder(new WebStateStorage())
        stateManager = new StateManager(new WebLifecycleManager(), localStateHolder)

        return true
    },
    triggerEvent(eventName: string, value?: string) {
        Logger.debug('onEvent -> $eventName, value: $value')
        localStateHolder.onEvent(eventName)
        // check if we have a survey for this event
        triggerValidator
            .onEvent(eventName, value, localStateHolder)
            .then((validatorResult) => {
                if (validatorResult) {
                    stateManager.showEventSurvey(validatorResult.surveyPresentation, validatorResult.ruleSet, eventName)
                }
            })
            .catch((error) => {
                Logger.error(`Failed to validate event trigger: ${error}`)
            })
    },
    pageOpened(pageName: string) {},
    pageClosed(pageName: string) {},
    User: {
        login: (userId: string, email?: string, phoneNumber?: string) => {},
        logout: () => {},
        addPhoneNumber: (phoneNumber: string) => {},
        addEmail: (email: string) => {},
        /**
         * Set the language of the user. This will be used to filter surveys.
         * language: ISO 639-1 code
         */
        setLanguage: (language: string) => {},
        addTag: (tags: Map<string, string>) => {}
    }
}
