import { CategoryItem, CategoryModel, ItemType, SurveyModel, SurveySelection, SurveyType } from '@feeba/types'
import { Box, BoxProps, Button, Stack, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { EndSurveyComponent } from './EndSurveyComponent'
import { MatrixTableComponent } from './MatrixTableComponent'
import { MultipleChoiceComponent } from './MultipleChoiceComponent'
import { NpsScoreComponent } from './NpsScoreComponent'

import { TextEntryComponent } from './TextEntiryComponent'

const splitCategoryItems = (categoryItems: CategoryItem[]): CategoryItem[][] => {
    const result: CategoryItem[][] = []
    let currentBlock: CategoryItem[] = []
    categoryItems.forEach((e) => {
        if (e.type === ItemType.Divider && e.isSet) {
            result.push(currentBlock)
            currentBlock = []
        } else {
            currentBlock.push(e)
        }
    })
    result.push(currentBlock)
    return result
}

export const BlockComponent: FC<Props> = ({ categoryUiModel, onSelectionUpdate, onNavigation, surveyUiModel, endSurvey, boxProps }: Props) => {
    const [subBlocks] = useState<CategoryItem[][]>(splitCategoryItems(categoryUiModel.categoryItems))
    const [currentSubBlockIndex, setCurrentSubBlockIndex] = useState<number>(0)
    const selections: SurveySelection[] = []
    const buttonBackgroundColor = '#FDDD34'

    const updateSelections = (selection: SurveySelection) => {
        {
            let found = false
            for (const s of selections) {
                console.log('s.questionId', s.questionId)
                if (s.questionId === selection.questionId) {
                    s.answers = selection.answers
                    found = true
                }
            }
            if (!found) selections.push(selection)
            onSelectionUpdate(selections)
        }
    }

    return (
        <Stack id={'block_container'} direction={'column'} sx={{ height: '100%' }}>
            {subBlocks[currentSubBlockIndex].map((e, i, arr) => {
                if (!endSurvey) {
                    if (e.type === ItemType.MultipleChoice) {
                        return (
                            <MultipleChoiceComponent
                                style={surveyUiModel.presentation.style}
                                item={e}
                                key={e.id}
                                onUpdated={updateSelections}
                                sx={{ ...getSx(i, arr, surveyUiModel) }}
                            />
                        )
                    } else if (e.type === ItemType.TextEntry) {
                        return (
                            <TextEntryComponent key={i} style={surveyUiModel.presentation.style} item={e} onUpdated={updateSelections} sx={{ ...getSx(i, arr, surveyUiModel) }} />
                        )
                    } else if (e.type === ItemType.NpsScore) {
                        return <NpsScoreComponent style={surveyUiModel.presentation.style} item={e} key={i} onUpdated={updateSelections} sx={{ ...getSx(i, arr, surveyUiModel) }} />
                    } else if (e.type === ItemType.MatrixTable) {
                        return (
                            <MatrixTableComponent key={i} item={e} style={surveyUiModel.presentation.style} onUpdated={updateSelections} sx={{ ...getSx(i, arr, surveyUiModel) }} />
                        )
                    } else {
                        console.error(`Rendering has not been implemented for item => ${e}`)
                        return <div key={i}></div>
                    }
                }
            })}

            {categoryUiModel.messageEnd?.visible && endSurvey && <EndSurveyComponent item={categoryUiModel.messageEnd} style={surveyUiModel.presentation.style} />}

            {surveyUiModel.properties.typeConfig?.type !== SurveyType.Inline && !endSurvey && (
                <Box style={{ flex: 1 }} bottom="20px" left="0" right="0" alignItems={'flex-end'} justifyContent={'center'} display={'flex'}>
                    <Button
                        disableElevation={true}
                        sx={{
                            m: 1,
                            textTransform: 'none',
                            borderRadius: '8px',
                            fontSize: '1rem',
                            height: '55px',
                            backgroundColor: buttonBackgroundColor,
                            width: { xs: 350, sm: 400, md: 450, lg: 200 },
                            '&:hover': {
                                backgroundColor: buttonBackgroundColor // Set the same background color on hover
                            }
                        }}
                        onClick={() => {
                            if (currentSubBlockIndex === subBlocks.length - 1) {
                                onNavigation(NavigationType.Next, false)
                            } else {
                                setCurrentSubBlockIndex(currentSubBlockIndex + 1)
                                onNavigation(NavigationType.Next, true)
                            }
                        }}
                    >
                        <Typography color={'black'} fontFamily={'-apple-system-subheadline, BlinkMacSystemFont, sans-serif'} variant={'h6'}>
                            {surveyUiModel.nextButtonText?.value ? surveyUiModel.nextButtonText.value : 'Next'}
                        </Typography>
                    </Button>
                </Box>
            )}
        </Stack>
    )
}

export interface Props {
    categoryUiModel: CategoryModel
    surveyUiModel: SurveyModel
    onSelectionUpdate: (selections: Array<SurveySelection>) => void
    onNavigation: (type: NavigationType, alreadyHandled: boolean) => void
    endSurvey?: boolean
    boxProps?: BoxProps
}
export enum NavigationType {
    Next,
    Previous
}

const getSx = (currentIndex: number, itemList: Array<CategoryItem>, surveyUiModel: SurveyModel) => {
    let vertical = { xs: 2, sm: 4 } // Default margin-top value
    if (surveyUiModel.presentation.style.questionSpacing === 'compact') {
        vertical = { xs: 1, sm: 2 }
    } else if (surveyUiModel.presentation.style.questionSpacing === 'comfortable') {
        vertical = { xs: 2, sm: 4 }
    } else if (surveyUiModel.presentation.style.questionSpacing === 'extended') {
        vertical = { xs: 4, sm: 6 }
    }
    return {
        ml: { xs: 2, sm: 4 },
        mr: { xs: 2, sm: 4 },
        mt: vertical,
        mb: vertical
    }
}
