import { Box, CircularProgress, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { fetchSurveyPresentation } from './RestClient'
import { parseUrl } from './utils'
// Themeing
import { ThemeProvider } from '@mui/material'
import theme from '../setup/muijs_theme'
import '../setup/setup.css'
import { SurveyRenderer, SurveyRendererProps } from './survey_renderer'
import { uuidv4 } from '../utils/uuid'
import { SurveyType } from '@feeba/types'

export const SurveyPage: FC<Props> = ({ url }) => {
    const [sessionId] = useState<string>(parseUrl()?.sessionId || uuidv4())

    const [data, setData] = useState<SurveyRendererProps | null>(null)
    const [error, setError] = useState<{ message: string } | null>(null)

    useEffect(() => {
        const parsed = parseUrl(url)
        if (!parsed) {
            console.log('Could not parse the url and retrieeve needed information')
            return
        }
        const { surveyId, projectName, lang } = parsed

        // fetch Survey from the server
        fetchSurveyPresentation(projectName, surveyId, lang)
            .then((e) => {
                const surveyModel = (e.survey.filter((e) => e.langCode === lang)?.[0] || e.survey[0])!
                setData({ surveyPresentation: e, surveyModel, projectName, surveyId, lang, sessionId })
                setError(null)
            })
            .catch((err) => {
                setData(null)
                setError({ message: err.message })
            })
    }, [])
    useEffect(() => {
        try {
            // Let the client library know that rendering of error is complete
            Mobile.surveyFullyRendered()
        } catch (e) {
            // no-op
        }
    }, [error])

    console.log('surveyPresentation:: data', data)

    const parentProps: Record<string, any> = {
        height: '100vh'
    }
    if (data) {
        // Inline surveys should not take the full height.
        if (data.surveyModel.properties.typeConfig?.type === SurveyType.Inline) {
            delete parentProps.height
        }
    }
    return (
        <ThemeProvider theme={theme}>
            <Box display="flex" justifyContent="center" sx={{ bgcolor: 'lightgrey', alignItems: 'center', ...parentProps }}>
                {data ? (
                    <SurveyRenderer {...data} />
                ) : (
                    <CircularProgress size={24} sx={{ color: 'primary.main', position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }} />
                )}
                {/* Shows an error message if there is an error. It is important to render the error message after the survey renderer. Error message takes precedence over the survey renderer. */}
                {error && (
                    <Box>
                        <Typography>{error.message}</Typography>
                    </Box>
                )}
            </Box>
        </ThemeProvider>
    )
}

export interface Props {
    url?: string
}
