import { MessageEndModel } from '@feeba/types'
import { Box, BoxProps, Button, Typography } from '@mui/material'
import { FC } from 'react'

export const EndSurveyComponent: FC<Props> = ({ item }: Props) => {
    console.log('End Survey Component')
    return (
        <Box key={`end-survey-`} width="100%" height={1} mt={5} position="relative">
            <Typography sx={{ m: 2 }} variant="h1" fontSize={21} gutterBottom textAlign={'center'}>
                {item.value}
            </Typography>

            <Typography textAlign={'center'} marginTop={5}>
                <Button
                    onClick={() => {
                        try {
                            Mobile.endOfSurvey('closeSurvey')
                        } catch (e) {
                            console.error('Mobile object is not found. If you are on Desktop browser that is expected. If you are on Mobile check if you have right intergration')
                        }
                    }}
                    variant="contained"
                >
                    Close
                </Button>
            </Typography>
        </Box>
    )
}

export interface Props extends BoxProps {
    item: MessageEndModel
}
