import { AppHistoryState, SurveyPartialResult, SurveySelection, UserData } from '@feeba/types'
import axios from 'axios'
import * as yup from 'yup'
import { SurveyRepresentation } from './Models'

export const fetchSurveyPresentation = async (projectName: string, surveyId: string, lang?: string): Promise<SurveyRepresentation> => {
    try {
        const result = await Mobile.getPrefetchedSurveyV1(projectName, surveyId, lang)
        if (!result) {
            throw new Error('There was no cached survey presentation found. Falling back to REST API call')
        }
        // 1. Parse the result
        // TODO s2. Validate the result
        // 3. Return the result
        return JSON.parse(result)
    } catch (e) {
        console.error('fetchSurveyPresentation::', e)
        return axios
            .get<SurveyRepresentation>(`/survey/client/presentation/${projectName}/${surveyId}`, {
                params: { lang }
            })
            .then((data) => data.data)
            .catch((err) => {
                console.error(`Error fetching tickets`, err)
                throw err
            })
    }
}

export const publishSurvey = (projectName: string, surveyId: string, sessionId: string, selections: Array<SurveySelection>): Promise<void> => {
    let data: string
    try {
        data = Mobile.getCurrentState()
    } catch (e) {
        console.error(e)
        console.log('Due to error we are falling back to the default data to let development and testing going on wihtout the mobile app')
        data =
            '{"numberOfLaunches":0,"totalSessionDurationSec":0,"lastTimeAppOpened":0,"lastTimeSurveyTriggered":{},"userData":{"userId":"test_user_id","email":"admin@google.com","phoneNumber":"+1-123-456-7890","tags":{},"langCode":"en"}}'
    }

    const state = appHistoryValidator.validateSync(JSON.parse(data))

    const body: SurveyPartialResult = {
        surveyId: surveyId,
        selections: selections,
        sessionId: sessionId,
        sdkData: state
    }

    return axios
        .post<void>(`/survey/client/result/${projectName}/${surveyId}`, body)
        .then((data) => data.data)
        .catch((err) => {
            console.error(`Error fetching tickets`, err)
            throw err
        })
}

const userDataSchema: yup.Schema<UserData> = yup
    .object({
        userId: yup.string().required(),
        email: yup.string().nullable(),
        phoneNumber: yup.string().nullable(),
        langCode: yup.string().nullable(),
        tags: yup.object()
    })
    .required()

const appHistoryValidator: yup.Schema<AppHistoryState> = yup.object({
    numberOfLaunches: yup.number().min(0, 'number of launches can not be less than 0').required(),
    totalSessionDurationSec: yup.number().min(0, 'total session duration can not be less than 0').required(),
    lastTimeAppOpened: yup.number().min(0, 'last time app opened can not be less than 0').required(),
    lastTimeSurveyTriggered: yup.mixed().defined().nullable(),
    userData: userDataSchema
})
