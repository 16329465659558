import { RuleSet, RuleType, SurveyPresentation, getSurveyDelaySec } from '@feeba/types'
import { LocalStateHolder, RestClient } from './data'
import { AppState, GenericAppLifecycle } from './lifecycle'
import { SurveyViewController } from './modal'

const PREFIX_DELAYED_TASK_PAGE = 'OnPage:'
const PREFIX_DELAYED_TASK_EVENT = 'OnEvent:'

enum AppVisibility {
    Backgrounded,
    Foregrounded
}

export class StateManager {
    private surveyController: SurveyViewController | null = null
    private delayedTasks: { [key: string]: NodeJS.Timeout } = {}
    private restClient = new RestClient()
    private appVisibility = AppVisibility.Backgrounded

    constructor(
        private lifecycle: GenericAppLifecycle,
        private localStateHolder: LocalStateHolder
    ) {
        this.lifecycle.appLifecycleListener = {
            onLifecycleEvent: async (state: AppState) => {
                console.log(`Application moved to ${state}`)
                switch (state) {
                    case AppState.CREATED:
                        console.log('StateManager::AppLifecycleEvent -> CREATED')
                        const localState = await this.localStateHolder.readAppHistoryState()
                        const updated = await this.restClient.getSurveyPlans(localState)
                        if (updated) {
                            this.localStateHolder.setFeebaConfig(updated)
                        }
                        this.appVisibility = AppVisibility.Foregrounded
                        break
                    case AppState.FOREGROUND:
                        this.appVisibility = AppVisibility.Foregrounded
                        break
                    case AppState.BACKGROUND:
                        this.appVisibility = AppVisibility.Backgrounded
                        break
                }
            }
        }
    }

    showEventSurvey(presentation: SurveyPresentation, ruleSet: RuleSet, associatedKey: string) {
        console.log('StateManager::showEventSurvey')
        this.internalShowSurvey(presentation, ruleSet, `${PREFIX_DELAYED_TASK_EVENT}${associatedKey}`)
    }

    showPageSurvey(presentation: SurveyPresentation, ruleSet: RuleSet, associatedKey: string) {
        console.log('StateManager::showPageSurvey')
        this.internalShowSurvey(presentation, ruleSet, `${PREFIX_DELAYED_TASK_PAGE}${associatedKey}`)
    }

    private internalShowSurvey(presentation: SurveyPresentation, ruleSet: RuleSet, associatedKey: string) {
        const surveyDelay = getSurveyDelaySec(ruleSet)
        if (surveyDelay > 0) {
            console.log(`StateManager::showSurvey - Scheduling survey for ${surveyDelay} Sec`)
            const timeoutId = setTimeout(() => {
                console.log('StateManager::showSurvey - Executing scheduled survey')
                this.initializeSurveyViewController(presentation, ruleSet)
            }, surveyDelay * 1000)
            this.delayedTasks[associatedKey] = timeoutId
        } else {
            console.log('StateManager::showSurvey - Showing survey immediately')
            this.initializeSurveyViewController(presentation, ruleSet)
        }
    }

    private async initializeSurveyViewController(presentation: SurveyPresentation, ruleSet: RuleSet) {
        if (this.appVisibility === AppVisibility.Backgrounded) {
            console.log('StateManager::showSurvey - App is in background, skipping')
            return
        }
        const surveyViewController = new SurveyViewController(
            presentation,
            ruleSet,
            await this.localStateHolder.readAppHistoryState(),
            () => {
                console.log('SurveyViewController::onSurveyWasShown')
            },
            () => {
                console.log('SurveyViewController::onSurveyWasDismissed')
                this.surveyController = null
            }
        )
        this.surveyController = surveyViewController
        surveyViewController.start(this.lifecycle)
    }

    pageClosed(pageName: string) {
        console.log(`StateManager::pageClosed -> ${pageName}`)
        this.cancelPendingSurveys(pageName, RuleType.SCREEN)
        if (this.surveyController) {
            this.surveyController.destroy(false)
            this.surveyController = null
        }
    }

    cancelEventRelatedSurveys(eventName: string) {
        console.log(`StateManager::cancelEventRelatedSurveys -> ${eventName}`)
    }

    private cancelPendingSurveys(pageName: string, ruleTypeToCancel: RuleType) {
        console.log(`ActivityLifecycleListener::cancelPendingSurveys:: delayedTasks -> ${this.delayedTasks}`)
        const key = ruleTypeToCancel === RuleType.SCREEN ? `${PREFIX_DELAYED_TASK_PAGE}${pageName}` : `${PREFIX_DELAYED_TASK_EVENT}${pageName}`
        const timeoutId = this.delayedTasks[key]
        if (timeoutId) {
            console.log('---> Removing ')
            clearTimeout(timeoutId)
            delete this.delayedTasks[key]
        }
    }
}
