const TAG = 'Feeba'

enum LogLevel {
    NONE = 0,
    DEBUG = 1,
    WARN = 2,
    ERROR = 3
}

export const Logger = {
    minLevel: LogLevel.NONE | LogLevel.DEBUG | LogLevel.WARN | LogLevel.ERROR,
    debug(message: string, error: Error | undefined = undefined): void {
        if (this.minLevel >= LogLevel.DEBUG) {
            console.debug(TAG, message, error)
        }
    },
    warn(message: string, error: Error | undefined = undefined): void {
        if (this.minLevel >= LogLevel.WARN) {
            console.warn(TAG, message, error)
        }
    },
    error(message: string, error: Error | undefined = undefined): void {
        if (this.minLevel >= LogLevel.ERROR) {
            console.error(TAG, message, error)
        }
    }
}
