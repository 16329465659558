import { SurveyPage } from '@feeba/web-sdk'
import React from 'react'
import ReactDOM from 'react-dom/client'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <React.StrictMode>
        <SurveyPage />
    </React.StrictMode>
)
