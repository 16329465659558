import { Answer, SurveySelection, SurveyStyleUiModel, TextEntryQuestionUiModel } from '@feeba/types'
import { BoxProps, FormControl, Stack, TextField, Typography } from '@mui/material'
import { ChangeEvent, FC, useState } from 'react'

export const TextEntryComponent: FC<Props> = ({ item, sx, onUpdated, style }) => {
    const [inputValue, setInputValue] = useState('')
    let debounceTimeout: NodeJS.Timeout | null = null

    const handleInputChange = (answer: Answer, event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value)

        // Debounce the input to avoid sending too many requests
        if (debounceTimeout) {
            clearTimeout(debounceTimeout)
        }
        debounceTimeout = setTimeout(
            () =>
                onUpdated({
                    questionId: item.id,
                    answers: [{ answerId: answer.id, customValue: event.target.value }]
                }),
            500
        )
    }

    const textEntryBoldTextStyle = {
        fontWeight: style.isQuestionBold ? 'bold' : 'normal', // Set the font weight conditionally
        fontFamily: style.fontFamily,
        fontSize: style.questionFontSize
    }

    // console.log('TextEntryComponent ', JSON.stringify(item, null, 2))
    return (
        <Stack direction={'column'} sx={sx}>
            <Typography variant={'h6'} style={textEntryBoldTextStyle}>
                {item.question.text}
            </Typography>
            {item.answers.map((a, i) => {
                return (
                    <FormControl style={{ marginTop: '5px' }} key={i}>
                        <TextField
                            label={null}
                            multiline
                            fullWidth
                            value={inputValue}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(a, e)}
                            InputProps={{
                                style: {
                                    fontFamily: style.fontFamily,
                                    fontWeight: style.isQuestionBold ? 'bold' : 'normal',
                                    fontSize: '16px',
                                    padding: '10px !important'
                                }
                            }}
                            style={{
                                fontFamily: style.fontFamily,
                                fontWeight: '300',
                                padding: '0px'
                            }}
                        />
                    </FormControl>
                )
            })}
        </Stack>
    )
}

interface Props extends BoxProps {
    item: TextEntryQuestionUiModel
    onUpdated: (selection: SurveySelection) => void
    style: SurveyStyleUiModel
}
