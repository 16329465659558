import { ObjectID } from '../utils/uuid'

export const shuffleArray = (array: Array<any>) => {
    let currentIndex = array.length,
        randomIndex

    // While there remain elements to shuffle.
    while (currentIndex > 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--

        // And swap it with the current element.
        ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
    }

    return array
}

//depricated
export const parseUrl = (url?: string): { sessionId: string; surveyId: string; projectName: string; lang: string } | null => {
    const paths = (url || window.location.pathname).split('/')
    // Read the last path from the url
    const surveyId = paths.pop()
    const projectName = paths.pop()
    console.log('surveyId', surveyId)
    if (!surveyId) {
        console.error('Invalid survey ID')
        return null
    }
    if (!projectName) {
        console.error('Invalid project name')
        return null
    }

    const urlObject = new URL(window.location.href)
    const lang = urlObject.searchParams.get('lang') || 'en'
    const sessionId = urlObject.searchParams.get('sessionId') || ObjectID()
    return { sessionId, surveyId, projectName, lang }
}
