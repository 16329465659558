import { SurveyModel, SurveySelection } from '@feeba/types'
import { Box } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import { SurveyRepresentation } from './Models'
import { publishSurvey } from './RestClient'
import { BlockComponent, NavigationType } from './components/BlockComponent'

export const SurveyRenderer: FC<SurveyRendererProps> = (surveyRendererProps) => {
    const [pageState, setPageState] = useState<{ currentBlockIndex: number }>({ currentBlockIndex: 0 })
    const [endSurvey, setEndSurvey] = useState<boolean>(false)
    const selectionRef = useRef(new Map<string, SurveySelection>())

    useEffect(() => {
        ;(window as any).onInlineViewClosed = () => {
            console.log('!!!!--- onInlineViewClosed ---!!!!')
            intermediateUpdate(Array.from(selectionRef.current.values()), surveyRendererProps).catch((err) => {
                console.error(err)
            })
        }

        return () => {
            ;(window as any).onInlineViewClosed = null
        }
    }, [])
    useEffect(() => {
        try {
            // Let the client library know that rendering is complete
            Mobile.surveyFullyRendered()
        } catch (e) {
            // no-op
        }
    }, [pageState])

    console.log('SurveyRenderer::pageState', pageState)

    return (
        <Box
            sx={{
                bgcolor: '#FFFFFF',
                width: { xs: '100%', sm: 500, lg: 750, xl: 1200 },
                height: '100%',
                overflow: 'hidden',
                overflowY: 'scroll'
            }}
            alignItems="center"
            height={1}
        >
            <BlockComponent
                categoryUiModel={surveyRendererProps.surveyModel.items[pageState.currentBlockIndex]}
                surveyUiModel={surveyRendererProps.surveyModel}
                // setEndSurvey={() => setEndSurvey(true)}
                endSurvey={endSurvey}
                onNavigation={(type, alreadyHandled) => {
                    if (alreadyHandled === false) {
                        if (type === NavigationType.Next) {
                            // if this is a last black, then we need to set the endSurvey to true
                            if (pageState.currentBlockIndex === surveyRendererProps.surveyModel.items.length - 1) {
                                setEndSurvey(true)
                            } else {
                                setPageState({ ...pageState, currentBlockIndex: pageState.currentBlockIndex + 1 })
                            }
                        } else if (type === NavigationType.Previous) {
                            setPageState({ ...pageState, currentBlockIndex: pageState.currentBlockIndex - 1 })
                        }
                    }
                    intermediateUpdate(Array.from(selectionRef.current.values()), surveyRendererProps).catch((err) => {
                        console.error(err)
                    })
                }}
                onSelectionUpdate={(selections) => {
                    selections.forEach((e) => selectionRef.current.set(e.questionId, e))
                }}
            />
        </Box>
    )
}

function intermediateUpdate(selections: SurveySelection[], surveyRendererProps: SurveyRendererProps): Promise<void> {
    console.log('intermediateUpdate', selections)
    const { projectName, surveyId, sessionId } = surveyRendererProps
    return publishSurvey(projectName, surveyId, sessionId, selections)
}

export interface SurveyRendererProps {
    surveyPresentation: SurveyRepresentation
    surveyModel: SurveyModel
    projectName: string
    surveyId: string
    lang: string
    sessionId: string
}
