import { RuleSet, RuleType, SurveyPresentation, TriggerRule } from '@feeba/types'
import { LocalStateHolder } from './data'

export class TriggerValidator {
    async onEvent(eventName: string, value: string | null = null, localStateHolder: LocalStateHolder): Promise<ValidatorResult | null> {
        console.debug(`TriggerValidator:: onEvent -> ${eventName}, value: ${value}`)
        // check if we have a survey for this event
        const config = await localStateHolder.readLocalConfig()
        console.debug(`TriggerValidator:: surveyPlans -> ${config.surveyPlans}`)
        for (const surveyPlan of config.surveyPlans) {
            for (const ruleSet of surveyPlan.ruleSetList) {
                // if all conditions are met, return the survey
                let allConditionsMet = false
                for (const triggerCondition of ruleSet.triggers) {
                    if (this.isEvent(triggerCondition) && triggerCondition.eventName === eventName) {
                        allConditionsMet = true
                    }
                }
                if (allConditionsMet) {
                    return new ValidatorResult(surveyPlan.surveyPresentation, 0, ruleSet)
                }
            }
        }
        return null
    }

    async pageOpened(pageName: string, localStateHolder: LocalStateHolder): Promise<ValidatorResult | null> {
        console.debug(`TriggerValidator:: pageOpened -> ${pageName}`)
        // check if we have a survey for this event
        const config = await localStateHolder.readLocalConfig()
        console.debug(`TriggerValidator:: surveyPlans -> ${config.surveyPlans}`)
        for (const surveyPlan of config.surveyPlans) {
            for (const ruleSet of surveyPlan.ruleSetList) {
                // if all conditions are met, return the survey
                let allConditionsMet = false
                if (this.isPageTrigger(ruleSet)) {
                    allConditionsMet = true
                }
                if (allConditionsMet) {
                    if (!ruleSet.triggers.some((trigger) => trigger.eventName === pageName)) {
                        // page name is not in the trigger conditions, we pass the condition block
                        continue
                    }
                    let surveyOpenDelaySec = 0
                    try {
                        const sessionDurationTrigger = ruleSet.triggers.find((trigger) => trigger.type === RuleType.SESSION_DURATION)
                        surveyOpenDelaySec = sessionDurationTrigger ? parseInt(sessionDurationTrigger.value) : 0
                    } catch (error) {
                        console.error(`Failed to parse page timing condition value: ${error}`)
                    }
                    return new ValidatorResult(surveyPlan.surveyPresentation, surveyOpenDelaySec * 1000, ruleSet)
                }
            }
        }
        return null
    }

    private isEvent(triggerCondition: TriggerRule): boolean {
        // Implement this method based on your logic
        return false
    }

    private isPageTrigger(ruleSet: RuleSet): boolean {
        // Implement this method based on your logic
        return false
    }
}

class ValidatorResult {
    constructor(
        public surveyPresentation: SurveyPresentation,
        public delay: number,
        public ruleSet: RuleSet
    ) {}
}
